<template>
  <div class="tags_all">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <MovieList :data-list="list" class="movieList" fromPath="tags" />
    </PullRefresh>
  </div>
</template>
<script>
import { queryTagList } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';
import MovieList from '@/components/MovieList';
import { getSessionItem } from '@/utils/longStorage';

export default {
  components: { MovieList, PullRefresh },
  name: 'tagsMovies',
  data() {
    return {
      params: {
        pageNumber: 1,
        pageSize: 10,
      },
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  created() {
    this.tagID = this.$route.query.id;
    if (getSessionItem('tagId')) {
      this.tagID = getSessionItem('tagId');
    }
    this.getList();
  },
  methods: {
    async getList(type) {
      let req = {
        tagID: this.tagID,
        pageNumber: String(this.params.pageNumber),
        pageSize: String(this.params.pageSize),
        newsType: 'MOVIE',
      };
      try {
        let res = await this.$Api(queryTagList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.params.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.params.pageNumber++;
      }
      this.getList();
    },
    onRefresh() {
      this.params.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
.tags_all {
  .movieList {
    padding: 12px 16px;
  }
}
</style>
